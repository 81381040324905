/* eslint-disable max-lines */

import { Suspense } from 'react';

import Image from 'Component/Image';
import {ImageRatio} from 'Component/Image/Image.type';
import Loader from 'Component/Loader';
import {AddToCart, ProductConfigurableAttributes} from 'Component/Product/Product.component';
import {ProductType} from 'Component/Product/Product.config';
import {ContentObject} from 'Component/ProductCard/ProductCard.type';
import ProductChevronIcon from 'Component/ProductChevronIcon';
import SelectedIcon from 'Component/SelectedIcon/SelectedIcon.component';
import TextPlaceholder from 'Component/TextPlaceholder';
import {TextPlaceHolderLength} from 'Component/TextPlaceholder/TextPlaceholder.config';
import {CategoryPageLayout} from 'Route/CategoryPage/CategoryPage.config';
import {ProductErpPrice} from 'SourceComponent/Product/Product.type';
import {
    ProductCardComponent as SourceProductCardComponent,
} from 'SourceComponent/ProductCard/ProductCard.component';
import {ProductAvailabilityData} from 'SourceQuery/ProductList.type';
import {ReactElement} from 'Type/Common.type';
import {isSignedIn} from 'Util/Auth/IsSignedIn';
import {isCrawler, isSSR} from 'Util/Browser';
import {getErpPriceValue} from 'Util/ErpPrice';
import {getNextWeekDates} from 'Util/GetNexWeekDates';
import {formatPrice} from 'Util/Price';
import {filterConfigurableOptions} from 'Util/Product';
import {IndexedConfigurableOption} from 'Util/Product/Product.type';

import './ProductCard.override.style';

/** @namespace Steinkrueger/Component/ProductCard/Component */
export class ProductCardComponent extends SourceProductCardComponent {
    contentObject: ContentObject = {
        renderCardLinkWrapper: this.renderCardLinkWrapper.bind(this),
        pictureBlock: {
            picture: this.renderPicture.bind(this),
        },
        content: {
            review: this.renderReviews.bind(this),
            productPrice: this.renderErpPrice.bind(this),
            mainDetails: this.renderMainDetails.bind(this),
            additionalProductDetails: this.renderBrand.bind(this),
        },
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    getConfigurableAttributes(): Record<string, IndexedConfigurableOption> {
        const {
            product: { configurable_options: configurableOptions = {}, variants = [] },
        } = this.props;

        return filterConfigurableOptions(configurableOptions, variants);
    }

    renderCardName(header = true, dynamic = false): ReactElement {
        const {
            product: { name },
            productName,
            device: { isMobile },
            layout,
        } = this.props;

        const nameToRender = dynamic ? productName : name;
        // eslint-disable-next-line no-magic-numbers
        const maxLength = isMobile ? 40 : 55;
        const result = nameToRender && nameToRender.length > maxLength
            ? `${nameToRender.substring(0, maxLength).trim() }...`
            : nameToRender;

        if (!header) {
            if (layout === CategoryPageLayout.LIST) {
                return (
                    <p block={ this.className } elem="Name">
                        <TextPlaceholder content={ nameToRender } length={ TextPlaceHolderLength.MEDIUM } />
                    </p>
                );
            }

            return (
                <p block={ this.className } elem="Name">
                    <TextPlaceholder content={ result } length={ TextPlaceHolderLength.MEDIUM } />
                </p>
            );
        }

        return (
            <h1 block={ this.className } elem="Title" itemProp="name">
                <TextPlaceholder content={ result } length={ TextPlaceHolderLength.MEDIUM } />
            </h1>
        );
    }

    renderLabel(): ReactElement {
        const { product } = this.props;
        const isFreshCut = product.is_fresh_cut ? __('Fresh Cut') : '';

        if (!isFreshCut) {
            return null;
        }

        return (
            <div block="ProductCard" elem="Label">
                { isFreshCut }
            </div>
        );
    }

    renderCardContent(): ReactElement {
        const { renderContent, product: { name }, isPlp } = this.props;
        const isLogged = isSignedIn();

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        if (isLogged) {
            if (isPlp) {
                return (
                    <div block="ProductCard" elem="LinkInnerWrapper" mods={ {loaded: !!name, isPlp} }>
                        <div block="ProductCard" elem="FigureReview">
                            { this.renderCardLinkWrapper((
                                <figure block="ProductCard" elem="Figure">
                                    { this.renderPicture() }
                                </figure>
                            )) }
                        </div>
                        <div block="ProductCard" elem="Content">
                            { this.renderCardLinkWrapper((
                                <figure block="ProductCard" elem="Figure">
                                    { this.renderCardName(false) }
                                </figure>
                            )) }
                            { this.renderLabel() }
                            { this.renderErpPrice() }
                            { this.renderConfigurableOptions() }
                            <div block="ProductCard" elem="Hovered">
                                { this.renderCardActions() }
                                { this.renderProductLink() }
                            </div>
                        </div>
                    </div>
                );
            }

            return (
                this.renderCardLinkWrapper((
                    <div block="ProductCard" elem="LinkInnerWrapper" mods={ {loaded: !!name} }>
                        <div block="ProductCard" elem="FigureReview">
                            <figure block="ProductCard" elem="Figure">
                                { this.renderPicture() }
                            </figure>
                        </div>
                        <div block="ProductCard" elem="Content">
                            { this.renderCardName(false) }
                            { this.renderLabel() }
                            { this.renderErpPrice() }
                        </div>
                    </div>
                ))
            );
        }

        return (
            this.renderCardLinkWrapper((
                <div block="ProductCard" elem="LinkInnerWrapper" mods={ {loaded: !!name} }>
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            { this.renderPicture() }
                        </figure>
                    </div>
                    <div block="ProductCard" elem="Content">
                        { this.renderCardName(false) }
                        { this.renderLabel() }
                    </div>
                </div>
            ))
        );
    }

    renderPicture(mix = {}): ReactElement {
        const { onLoad } = this.props;
        const product = this.props.selectedProduct || this.props.product;

        const variantImage = this.props.selectedProduct && this.props.selectedProduct.image
            && this.props.selectedProduct.image.url
            ? this.props.selectedProduct.image.url : this.props.thumbnail;

        const { id, name } = product;

        return (
            <>
                <Image
                  imageRef={ this.imageRef }
                  src={ variantImage }
                  alt={ name }
                  ratio={ ImageRatio.IMG_CUSTOM }
                  mix={ { block: 'ProductCard', elem: 'Picture', mix } }
                  isPlaceholder={ !id }
                  onImageLoad={ onLoad }
                />
                <img
                  style={ { display: 'none' } }
                  alt={ name }
                  src={ variantImage }
                />
            </>
        );
    }

    renderSku(): ReactElement {
        const { getActiveProduct } = this.props;
        const { sku } = getActiveProduct();

        return <div block={ this.className } elem="Sku" itemProp="sku">{ __('SKU: %s', sku) }</div>;
    }

    renderConfigurableOptions(): ReactElement {
        const {
            setActiveProduct,
            parameters,
            product: { type_id: type, variants = [] },
            inStock,
            addToCartTriggeredWithError,
            updateAddToCartTriggeredWithError,
        } = this.props;

        if (type !== ProductType.CONFIGURABLE) {
            return null;
        }

        return (
            <div
              block="ProductActions"
              elem="AttributesWrapper"
            >
                <Suspense fallback={ null }>
                    <ProductConfigurableAttributes
                        // eslint-disable-next-line no-magic-numbers
                      numberOfPlaceholders={ [2, 4] }
                      updateAddToCartTriggeredWithError={ updateAddToCartTriggeredWithError }
                      addToCartTriggeredWithError={ addToCartTriggeredWithError }
                      mix={ { block: this.className, elem: 'Attributes' } }
                      parameters={ parameters }
                      variants={ variants }
                      updateConfigurableVariant={ setActiveProduct }
                      configurable_options={ this.getConfigurableAttributes() }
                      isContentExpanded
                      inStock={ inStock }
                      showProductAttributeAsLink={ false }
                    />
                </Suspense>
            </div>
        );
    }

    renderErpPrice(): ReactElement {
        const {
            erpPrice = '',
            isProductScopeSelectedByUser,
            product: { type_id: type, prices, attributes },
            selectedProduct,
            selectedProductScope,
            productErpPrices,
            isPlp,
        } = this.props;

        if (!getErpPriceValue(erpPrice)) {
            return null;
        }

        if (!isPlp && type === ProductType.CONFIGURABLE) {
            return null;
        }

        if (!erpPrice) {
            return (
                <span block="ProductCard" elem="Price">
                    <TextPlaceholder length={ TextPlaceHolderLength.MEDIUM } />
                </span>
            );
        }

        if (!isProductScopeSelectedByUser) {
            const productPrices = selectedProduct ? selectedProduct.prices : prices;
            const selectedProductAttributes = selectedProduct ? selectedProduct.attributes : attributes;

            const defaultPriceUnitValue = selectedProductAttributes && selectedProductAttributes.default_price_unit
                ? selectedProductAttributes.default_price_unit.attribute_value : '';

            if (defaultPriceUnitValue) {
                const defaultPriceUnitLabel = defaultPriceUnitValue && selectedProductAttributes
                    ? selectedProductAttributes.default_price_unit
                        .attribute_options[defaultPriceUnitValue].label : '';
                const defaultLabel = selectedProductAttributes
                    // eslint-disable-next-line max-len
                    && selectedProductAttributes[`${defaultPriceUnitLabel}_unit` as keyof typeof selectedProductAttributes];
                const labelToShow = defaultLabel?.attribute_options[defaultLabel.attribute_value].label
                    || defaultPriceUnitLabel;

                const priceValue = productPrices
                    && productPrices[`${defaultPriceUnitLabel}_price` as keyof typeof productPrices];

                return (
                    <span block="ProductCard" elem="Price">
                        <span block="ProductPrice" elem="PriceValue">
                            <span>
                                { formatPrice(Number(priceValue)) }
                            </span>
                        </span>
                        { labelToShow
                            // eslint-disable-next-line max-len
                            && <span block="ProductPrice" elem="UnitLabel">{ __(' per ') + labelToShow }</span> }
                    </span>
                );
            }
        }

        const selectedProductType = productErpPrices?.filter((item) => (item.price_type === selectedProductScope));
        const selectedPriceLabel = selectedProductType && selectedProductType[0].label;

        return (
            <span block="ProductCard" elem="Price">
                <span block="ProductPrice" elem="PriceValue">
                    <span>
                        { erpPrice }
                    </span>
                </span>
                { selectedPriceLabel
                    && <span block="ProductPrice" elem="UnitLabel">{ __(' per ') + selectedPriceLabel }</span> }
            </span>
        );
    }

    renderCardListContent(): ReactElement {
        const { renderContent, product: { name }, isPlp } = this.props;

        const isLogged = isSignedIn();

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        if (isLogged) {
            if (isPlp) {
                return (
                    <div block="ProductCard" elem="Link" mods={ {loaded: !!name} }>
                        <div block="ProductCard" elem="FigureReview">
                            { this.renderCardLinkWrapper((
                                <figure block="ProductCard" elem="Figure">
                                    { this.renderPicture() }
                                </figure>
                            )) }
                        </div>
                        <div block="ProductCard" elem="CardListWrapper">
                            <div block="ProductCard" elem="Content">
                                { this.renderCardLinkWrapper((
                                    <figure block="ProductCard" elem="Figure">
                                        { this.renderCardName(false) }
                                    </figure>
                                )) }
                                { this.renderLabel() }
                                { this.renderErpPrice() }
                            </div>
                            <div block="ProductCard" elem="ConfigurableOptions">
                                { this.renderConfigurableOptions() }
                                <div block="ProductCard" elem="ListSkuWrapper">
                                    { this.renderSku() }
                                    { this.renderProductLink() }
                                </div>
                            </div>
                            <div block="ProductCard" elem="Hovered">
                                <div block="ProductCardListOptions">
                                    { this.renderCustomAndBundleOptions() }
                                </div>
                                { this.renderCardActions() }
                            </div>
                        </div>
                    </div>
                );
            }

            return (
                this.renderCardLinkWrapper((
                    <div block="ProductCard" elem="Link" mods={ {loaded: !!name} }>
                        <div block="ProductCard" elem="FigureReview">
                            <figure block="ProductCard" elem="Figure">
                                { this.renderPicture() }
                            </figure>
                        </div>
                        <div block="ProductCard" elem="Content">
                            { this.renderCardName(false) }
                            { this.renderLabel() }
                            { this.renderErpPrice() }
                        </div>
                    </div>
                ))
            );
        }

        return (
            this.renderCardLinkWrapper((
                <div block="ProductCard" elem="Link" mods={ {loaded: !!name} }>
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            { this.renderPicture() }
                        </figure>
                    </div>
                    <div block="ProductCard" elem="Content">
                        { this.renderCardName(false) }
                        { this.renderLabel() }
                    </div>
                </div>
            ))
        );
    }

    renderProductLink(): ReactElement {
        return (
            <div block="SimpleProductLink">
                { this.renderCardLinkWrapper(
                    <span block="SimpleProductLink" elem="Inner">
                        <span>{ __('to the detail page') }</span>
                        <ProductChevronIcon />
                    </span>,
                ) }
            </div>
        );
    }

    renderCustomAndBundleOptions(): ReactElement {
        const {
            product: {
                options,
            },
            selectedProduct,
            product,
            configFormRef,
        } = this.props;

        const availability_data = selectedProduct
            ? selectedProduct.availability_data : product.availability_data;

        const deliveryDate = options?.find(
            (item) => item.title === 'Delivery Date',
        );

        const nextWeekDates: string[] = getNextWeekDates();
        const missedDates: ProductAvailabilityData[] = [];
        const availabilityData = availability_data || [];

        nextWeekDates.forEach((item: string) => {
            // eslint-disable-next-line max-len
            const isInTheNextWeek = availabilityData.filter((elem: ProductAvailabilityData) => elem.date === item).length;

            if (!isInTheNextWeek) {
                missedDates.push({
                    date: item,
                    is_available: false,
                });
            }
        });

        availabilityData.push(...missedDates);
        availabilityData.sort(
            // @ts-ignore
            (a: DeliveryDataItem, b: DeliveryDataItem) => (new Date(a.date) - new Date(b.date)),
        );

        if (deliveryDate) {
            return (
                <Suspense fallback={ null }>
                    { availabilityData && (
                        <>
                            { this.renderDeliveryDateItems(availabilityData) }
                        </>
                    ) }
                </Suspense>
            );
        }

        return (
            <form ref={ configFormRef }>
                { this.renderCustomizableOptions() }
            </form>
        );
    }

    renderDeliveryDateItems(dataItems: ProductAvailabilityData[] = []): ReactElement {
        if (!dataItems.length) {
            return null;
        }

        return (
            <div block="DeliveryDate">
                <div block="DeliveryDate" elem="Items">
                    { dataItems.map(this.renderDeliveryDateItem.bind(this)) }
                </div>
            </div>
        );
    }

    renderDeliveryDateItem(item: ProductAvailabilityData): ReactElement {
        const {date, is_available} = item;
        const {
            product: {
                options,
            },
            handleDeliveryDate,
            selectedDeliveryDate,
        } = this.props;

        const deliveryDate = options?.find(
            (item) => item.title === 'Delivery Date',
        );

        if (handleDeliveryDate && deliveryDate) {
            const { uid } = deliveryDate;
            const isSelected = selectedDeliveryDate === date;
            const jsDate = new Date(date);
            const days = [__('Sun'), __('Mon'), __('Tue'), __('Wed'), __('Thu'), __('Fri'), __('Sat')];
            const weekDay = days[ jsDate.getDay() ];

            return (
                <button
                    // eslint-disable-next-line react/jsx-no-bind
                  onClick={ (e) => handleDeliveryDate(e, date, uid) }
                  block="Button DeliveryDate"
                  elem="Item"
                  mods={ { isSelected } }
                  disabled={ !is_available }
                  key={ date }
                >
                    { isSelected && <SelectedIcon /> }
                    <span>{ weekDay }</span>
                </button>
            );
        }

        return null;
    }

    renderCardActions(): ReactElement {
        const { erpPrice = '', layout } = this.props;

        if (!getErpPriceValue(erpPrice)) {
            return null;
        }

        if (layout === CategoryPageLayout.LIST) {
            return (
                <>
                    { this.renderProductPriceScope() }
                    { this.renderAddToCartActionBlock() }
                </>
            );
        }

        return (
            <>
                { this.renderCustomAndBundleOptions() }
                { this.renderProductPriceScope() }
                { this.renderAddToCartActionBlock() }
            </>
        );
    }

    renderAddToCartActionBlock(): ReactElement {
        return (
            <div
              block="ProductActions"
              elem="AddToCartWrapper"
              mods={ { isPrerendered: isSSR() || isCrawler() } }
            >
                <Suspense fallback={ null }>
                    <div>
                        { this.renderQuantityChanger() }
                    </div>
                </Suspense>
                { this.renderAddToCartButton() }
            </div>
        );
    }

    renderAddToCartButton(layout = CategoryPageLayout.GRID): ReactElement {
        const {
            addToCart,
            inStock,
            quantity,
            getActiveProduct,
            updateSelectedValues,
            erpPrice = '',
        } = this.props;

        const isPriceNull = !getErpPriceValue(erpPrice);
        const isDisabled = !inStock || isPriceNull;

        return (
            <Suspense fallback={ null }>
                <AddToCart
                  mix={ { block: this.className, elem: 'AddToCart' } }
                  addToCart={ addToCart }
                  isDisabled={ isDisabled }
                  isIconEnabled
                  layout={ layout }
                  updateSelectedValues={ updateSelectedValues }
                  quantity={ quantity }
                  product={ getActiveProduct() }
                />
            </Suspense>
        );
    }

    renderProductPriceScope(): ReactElement {
        const { productErpPrices } = this.props;

        if (!productErpPrices || !productErpPrices.length) {
            return (
                <div block="ProductScope" elem="Loading">
                    <TextPlaceholder length={ TextPlaceHolderLength.MEDIUM } />
                </div>
            );
        }

        return (
            <div block="ProductScope">
                <div block="ProductScope" elem="List">
                    { productErpPrices.map(this.renderProductPriceScopeItem.bind(this)) }
                </div>
            </div>
        );
    }

    renderProductPriceScopeItem(item: ProductErpPrice): ReactElement {
        const {
            changePrice,
            selectedProductScope,
        } = this.props;

        const {
            price_type,
            label,
        } = item;

        if (!changePrice) {
            return null;
        }

        const isActive = selectedProductScope === price_type;

        return (
            <button
                // eslint-disable-next-line react/jsx-no-bind
              onClick={ (event) => changePrice(price_type, event) }
              block="Button"
              elem="ProductScope"
              mods={ {isActive} }
              key={ price_type }
            >
                <span>{ __(label) }</span>
            </button>
        );
    }

    render(): ReactElement {
        const {
            mix,
            isLoading,
            layout,
        } = this.props;

        if (layout === CategoryPageLayout.LIST) {
            return (
                <li
                  block="ProductCard"
                  mods={ { layout } }
                  mix={ mix }
                >
                    <Loader isLoading={ isLoading } />
                    { this.renderCardListContent() }
                </li>
            );
        }

        return (
            <li
              block="ProductCard"
              mods={ { layout } }
              mix={ mix }
            >
                <Loader isLoading={ isLoading } />
                { this.renderCardContent() }
            </li>
        );
    }
}

export default ProductCardComponent;
