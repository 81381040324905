import { connect } from 'react-redux';
import {Dispatch} from 'redux';

import {UrlRewrite} from 'Query/ProductList.type';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductCardContainer as SourceProductCardContainer,
} from 'SourceComponent/ProductCard/ProductCard.container';
import {Url} from 'Type/Common.type';
import history from 'Util/History';
import {RootState} from 'Util/Store/Store.type';
import {appendWithStoreCode} from 'Util/Url';

/** @namespace Steinkrueger/Component/ProductCard/Container/mapStateToProps */
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
});

/** @namespace Steinkrueger/Component/ProductCard/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
});

/** @namespace Steinkrueger/Component/ProductCard/Container */
export class ProductCardContainer extends SourceProductCardContainer {
    getLinkTo(): Url | undefined {
        const {
            baseLinkUrl,
            productUsesCategories,
            categoryUrlSuffix,
            product: { url, url_rewrites = [] },
            product,
        } = this.props;
        const { pathname: storePrefix } = new URL(baseLinkUrl || window.location.origin);
        const { location: { pathname } } = history;

        if (!url) {
            return undefined;
        }

        const { state: { category = null } = {} } = history.location;
        const categoryUrlPart = pathname.replace(storePrefix, '').replace(categoryUrlSuffix, '');
        const productUrl = `${categoryUrlPart}/${url.replace(storePrefix, '')}`;
        const rewriteUrl: Partial<UrlRewrite> = url_rewrites.find(({ url }) => url.includes(productUrl)) || {};
        const rewriteUrlPath = productUsesCategories
            ? (rewriteUrl.url && appendWithStoreCode(rewriteUrl.url)) || url
            : url;

        return {
            pathname: rewriteUrlPath,
            state: { product, prevCategoryId: category },
            search: '',
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
